@import url("https://fonts.googleapis.com/css2?family=Asap:wght@300;400;600;700&family=Bebas+Neue&family=Lexend:wght@400;500;600&family=Martian+Mono:wght@300;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f6f6f6;
}

/* * {
  cursor: default;
} */
.btnprimary {
  background-color: #378eb5;
  color: #fafafa;
  border-radius: 8px;
  padding: 14px 12px;
  font-weight: 600;
}

.btnApprove {
  background-color: #378eb5;
  color: #fafafa;
  border-radius: 8px;
  padding: 8px 12px;
  font-weight: 600;

}

.btnExport {
  background-color: #378eb5;
  color: #fafafa;
  border-radius: 8px;
  padding: 12px 12px;
  font-weight: 600;


}

.btnReject {
  color: #378eb5;
  border-radius: 8px;
  padding: 8px 12px;
  font-weight: 600;
  border: 1px solid #378eb5;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Smooth transition */

}


.btnReject:hover {
  background-color: #378eb5;
  color: white;
}

.btnprimary:hover {
  background-color: #0c4f6dc2;
}

ul.siderbarulist li:hover a {
  font-weight: 600;
}

.siderbar {
  height: calc(100dvh - 85px);
  overflow-y: auto;
  overflow-x: hidden;
}

.siderbar::-webkit-scrollbar {
  display: none; 
  width: 0;
}

.siderbar {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.siderbar::-webkit-scrollbar-track {
  display: none;
}


.statuscard {
  background-image: url(../public/images/statuscrd-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 160px;
  border-radius: 22px;
}

.checkbox:checked+.checkbox-label::before {
  background-color: #378eb5;
}

table {
  width: 100%;
  border-collapse: collapse;
  padding: 12px;
}

table thead {
  border-radius: 6px;
  background: var(--Secondary-Shades-10, #ebedef);
  padding: 16px 0;
  position: sticky;
  top: 0;
  z-index: 9;
}

table thead th {
  text-align: start;
  font-size: 14px;
  color: #333333;
  padding: 8px;
}

table thead tr th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  min-width: 25px;
}

table thead tr th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

table tr {
  height: 48px;
}

table tr td {
  padding: 8px;
  font-size: 14px;
  color: #5c5c5c;
  cursor: pointer;
}

table tr td:first-child {
  font-size: 14px;
  color: #5c5c5c;
}

table tr td:nth-child(2) {
  font-size: 14px;
  color: #33475f;
  font-weight: 600;
  cursor: pointer;
  /* text-decoration: underline; */
}

table tbody tr {
  cursor: pointer;
  background-color: #fafafafa;
  border: 5px solid #f6f6f6;
}

table tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

table tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  /* text-align: end; */
}

a {
  cursor: pointer;

}

.tablecontainer::-webkit-scrollbar {
  height: 6px;
  /* Set your desired scrollbar width */
  width: 100%;
}

.tablecontainer::-webkit-scrollbar-thumb {
  background-color: #adadad;
  border-radius: 8px;
  /* Set the color of the scrollbar thumb */
}

.slider .Attachments {
  display: flex !important;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-bottom: 20px;
}

.slider .Attachments img {
  height: 100%;
  max-height: 320px;
}

.slider ul.slick-dots.slick-thumb {
  margin-bottom: -30px;
}

.currentPage button {
  border-radius: 4px;
  color: #333333;
  padding: 4px 12px;
}

.currentPage button.active {
  border-radius: 8px;
  color: #fff;
  background-color: #33475f;
}

ul.AssetsOrderList::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #a3a3a3;
  /* Set the color of the scrollbar thumb */
  border-radius: 8px;
  /* Set the border radius to create rounded corners */
}

ul.AssetsOrderList::-webkit-scrollbar {
  width: 4px;
  /* Set the width of the scrollbar track */
}


.Employees {
  cursor: pointer;
}

.Employees::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #a3a3a3;
  /* Set the color of the scrollbar thumb */
  border-radius: 4px;
  /* Set the border radius to create rounded corners */
}

.Employees::-webkit-scrollbar {
  width: 4px;
  /* Set the width of the scrollbar track */
}

.react-datepicker-wrapper {
  width: 100%;
}

.css-13cymwt-control {
  background-color: white !important;
  border-radius: 8px !important;
  padding: 3px 6px !important;
  border: 1px solid #d1d5db !important;
}

.css-16xfy0z-control {
  background-color: white !important;
  border-radius: 8px !important;
  padding: 3px 6px !important;
  border: 1px solid #d1d5db !important;
}

.slick-dots li {
  overflow-y: hidden;
}

/* .css-13cymwt-control.no-data {
  border: 1px solid red !important;
}
.css-16xfy0z-control.no-data {
  border: 1px solid red !important;
} */
.react-datepicker-popper {
  z-index: 100 !important;
}

/* -----------------resposive-section---------------------- */
@media only screen and (max-width: 1280px) {
  .sign-up-component {
    position: fixed;
    z-index: 99;
    left: -140%;
    top: 0;
    transition: all 0.5s;
  }

  .sign-up-component.toggled {
    left: 0;
    transition: all 0.5s;
  }

  .siderbar {
    height: calc(100dvh);
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 300px;
  }
}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 768px) {
  table td {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px !important;

  }

  table tr td:first-child {
    display: flex;
    justify-content: start;
  }

  table thead {
    display: none;
  }

  table td::before {
    content: attr(data-cell);
    text-align: start;
    font-size: 12px;
    font-weight: 700;
    color: #333333;
  }

  table tbody tr {
    cursor: pointer;
    background-color: #fafafafa;
    height: 100%;
    display: block;
    border-radius: 16px;
  }

  table tr td:nth-child(2) {
    text-decoration: none !important;
  }
}

@media only screen and (max-width: 576px) {
  .statuscard {
    height: 140px;
  }
}



.datePerk .react-datepicker-popper {
  transform: inherit !important;
  top: 100% !important;
}

.datePerk .react-datepicker__triangle {
  transform: translate(29px, 0px) !important;
}